@value white, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, md, sm, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointLgUp,breakpointMdUp, breakpointXlUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value HEADER_HEIGHT_PX, HEADER_HEIGHT_MOBILE_PX  from "@/modules/styling/global.module.css";

@layer components {
  .container {
    align-items: center;
    background-color: white;
    border-bottom: 1px solid lightGrey2;
    display: flex;
    justify-content: space-between;
    margin-bottom: md;
    overflow-x: scroll;
    padding: md sm;
    position: sticky;
    top: HEADER_HEIGHT_MOBILE_PX;
    width: 100%;
    z-index: 1;

    button {
      font-weight: bold;
    }

    @media breakpointMdUp {
      top: HEADER_HEIGHT_PX;
      max-width: calc(100vw - 300px);
    }

    @media breakpointLgUp {
      justify-content: flex-start;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .desktopContainer {
    display: none;
    gap: md;

    button {
      flex-shrink: 0;

      @media breakpointXlUp {
        min-width: 150px;
      }
    }

    @media breakpointLgUp {
      display: flex;
    }
  }

  .mobileContainer {
    @media breakpointLgUp {
      display: none;
    }
  }

  .countText {
    align-items: center;
    display: flex;
    margin-right: sm;

    @media breakpointMdUp {
      margin-right: xl;
    }

    @media breakpointLgUp {
      min-width: 80px;
    }
  }

  .clear {
    border: 0;
    display: none;
    min-width: 100px;

    @media breakpointLgUp {
      display: block;
    }
  }

  button.moreActions {
    border: 0;
    min-width: 0;
    margin-left: -8px;
    margin-top: 2px;

    svg {
      transform: rotate(90deg);
    }
  }

  .modalContainer,
  .unboostCta {
    display: flex;
    flex-direction: column;
  }

  .modalContainer {
    flex-grow: 1;
    gap: md;
    padding: 0 xl;

    button {
      padding: sm 0;
    }
  }

  .unboostCta {
    @media breakpointLgUp {
      display: none;
    }
  }

  .selectAll {
    display: flex;
    margin: 0 lg 0 sm;
    padding: sm 0;
    align-items: center;
  }

  .selectAll label {
    align-items: center;
    cursor: pointer;
    display: flex;

    span {
      margin-left: sm;
    }
  }
}
