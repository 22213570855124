@value md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointXsUp, breakpointSmUp,breakpointSmDown,  breakpointMdUp, breakpointLgUp, breakpointXlUp, breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value white, lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value emptyStatePanelMessage from "@/modules/styling/zIndex.module.css";

@layer components {
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 176px;
  }

  .emptyMessage {
    position: absolute;
    min-width: 340px;
    background: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: emptyStatePanelMessage;

    @media breakpointSmDown {
      min-width: unset;
      left: md;
      right: md;
    }
  }

  .emptySlide {
    position: relative;
    background: lightGrey1;
    width: 100%;
    padding-top: 100%;
  }

  .slidePlaceholderRow {
    display: grid;
    gap: md;
    flex: 1;
    grid-template-columns: repeat(1, 1fr);

    @media breakpointXsUp {
      grid-template-columns: repeat(var(--xs), 1fr);
    }

    @media breakpointSmUp {
      grid-template-columns: repeat(var(--sm), 1fr);
    }

    @media breakpointMdUp {
      grid-template-columns: repeat(var(--md), 1fr);
    }

    @media breakpointLgUp {
      grid-template-columns: repeat(var(--lg), 1fr);
    }

    @media breakpointXlUp {
      grid-template-columns: repeat(var(--xl), 1fr);
    }
  }
}
