@value silverChalice, governorBay, black, doveGray from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value profileActionMenu from "@/modules/styling/zIndex.module.css";

@layer components {
  .navItem {
    display: none;

    @media breakpointMdUp {
      display: flex;
      align-items: center;
    }
  }

  .avatarWrapper {
    width: 30px;
    height: 30px;
    position: relative;

    img,
    .avatar__initials {
      border-width: 1px;
      border-style: solid;
      border-color: silverChalice;
      border-radius: 50%;

      &.isActive {
        border-color: governorBay;
      }

      &.isOpen {
        border-color: black;
      }
    }
  }

  .dropdownArrow {
    margin-left: 4px;
    height: 14px;
    width: 14px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    background-color: transparent;
    margin-left: 2px;

    &:hover {
      img,
      .avatar__initials {
        border-color: doveGray;

        &.isActive {
          border-color: governorBay;
        }
      }
    }
  }

  .actionMenu{
    div {
      z-index: profileActionMenu;
    }
  }
}
