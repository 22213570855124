@value black, white, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sellerHubHeaderContainerMobile, sellerHubHeaderContent from "@/modules/styling/zIndex.module.css";
@value HEADER_HEIGHT: 128px;

@layer components {
    .headerContainer {
        position: relative;
        height: HEADER_HEIGHT;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        background: black;

        @media breakpointMdDown {
            z-index: sellerHubHeaderContainerMobile;
        }
    }

    .headerContent {
        display: flex;
        align-items: center;
        z-index: sellerHubHeaderContent;
        margin: 0 md;

        @media breakpointMdDown {
            flex-direction: column;
        }
    }

    .mobileAvatar {
        display: none;

        @media breakpointMdDown {
            display: unset;
        }
    }

    .desktopAvatar {
        display: unset;

        @media breakpointMdDown {
            display: none;
        }
    }

    .headerText {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: white;
        margin-left: md;

        @media breakpointMdDown {
            margin-left: 0;
            padding: sm md 0;
            max-width: 100vw;
        }
    }

    .backgroundImage {
        position: absolute;
        inset: 0;
        filter: blur(40px);
        background-color: midGrey;
    }

    .backgroundImageWithAvatar {
        background-image: var(--avatar);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transform: scale(1.05);
    }
}