@value lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value HEADER_HEIGHT_PX, HEADER_HEIGHT_MOBILE_PX  from "@/modules/styling/global.module.css";

@layer components {
  .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    background-color: lightGrey1;
    overflow-y: auto;
    max-height: calc(100vh - HEADER_HEIGHT_MOBILE_PX);

    @media breakpointLgUp {
      max-height: calc(100vh - HEADER_HEIGHT_PX);
    }
  }
}
