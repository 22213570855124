@value xs from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value ceruleanBlue_r, ceruleanBlue_g, ceruleanBlue_b, a11yYellow from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
    .button {
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        margin-left: xs;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: rgba(ceruleanBlue_r, ceruleanBlue_g, ceruleanBlue_b, 0.2);
        }

        &:focus {
            box-shadow: 0 0 0 xs a11yYellow;
            outline: xs dotted transparent;
            border-radius: inherit;
        }
    }
}