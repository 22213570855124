@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer component {
  .container {
    display: grid;
    grid-template: repeat(2, minmax(1px, auto)) 1fr / 1fr 5fr;
    gap: sm md;
    margin-bottom: sm;

    @media breakpointMdDown {
      grid-template-columns: 1fr 1.5fr;
    }
  }

  .username {
    display: inline-block;
  }

  .image {
    grid-row: 1 / 4;
  }

  .ratingContainer {
    display: flex;
    gap: 2px;
  }
}
