@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .subtitle {
        @media breakpointSmDown {
            display: none;
        }
    }
}