@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value overlayDark, white, a11yYellow from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value popoverLayer, simpleDrawerWrapperLayer, simpleDrawerWrapperMobileLayer from "@/modules/styling/zIndex.module.css";
@value HEADER_HEIGHT_PX, HEADER_AND_MEGA_NAV_HEIGHT_DESKTOP_PX from "@/modules/styling/global.module.css";

@layer components {
  .overlay {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    inset: 0;
    background: overlayDark;
    z-index: popoverLayer;
  }

  .wrapper {
    display: inline-block;
    height: calc(100vh - HEADER_HEIGHT_PX);
    background: white;
    overflow: auto;
    position: fixed;
    top: HEADER_HEIGHT_PX;
    right: 0;
    transform: translate3d(100%, 0, 0);
    bottom: 0;
    outline: none;
    box-shadow: 0 4px 4px rgba(38, 38, 38, 0.45);
    z-index: simpleDrawerWrapperLayer;
    transition: transform 0.2s ease;
    will-change: transform;

    @media breakpointMdDown {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      top: 0;
      z-index: simpleDrawerWrapperMobileLayer;
    }
  }

  .pageWithMegaNavWrapper {
    height: calc(100vh - HEADER_HEIGHT_PX);
    top: HEADER_AND_MEGA_NAV_HEIGHT_DESKTOP_PX;
  }

  .fullHeightWrapper {
    height: 100vh;
    top: 0;
  }

  .visibleWrapper {
    transform: translate3d(0, 0, 0);
  }

  .header {
    text-align: right;
    position: sticky;
    top: 0;
    background: white;
    z-index: popoverLayer;
    padding: 24px;
  }

  .headerTitle {
    display: flex;
    justify-content: space-between;
  }

  .title {
    padding: md;
  }

  .flexGrow {
    flex-grow: 1;

    @media breakpointLgUp {
      display: none;
    }
  }

  .closeButton {
    padding: md;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 0 2px a11yYellow;
      outline: 4px dotted transparent;
    }
  }
}
