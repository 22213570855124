@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value lg, md, sm, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px solid lightGrey2;
    padding: lg xl;
    gap: sm;
  }

  .button {
    margin: md lg 0 lg;
  }
}
