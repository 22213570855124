@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value lightGrey2, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value mobileProductActions from "@/modules/styling/zIndex.module.css";

@layer components {
  .container {
    display: none;

    @media breakpointMdDown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      left: 0;
      right: 0;
      padding: md;
      background: white;
      border-top: 1px solid lightGrey2;
      z-index: mobileProductActions;
    }
  }
}
