@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .closeButton {
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: md;
  }
}
