@value black, doveGray from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fallbackFonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
@value fontFamilySelect: var(--font-GtAmericaExpanded), "Arial Black", fallbackFonts;
@value selectContainerSvgLayer from "@/modules/styling/zIndex.module.css";

@layer components {
  .selectContainer {
    position: relative;
    border-radius: 2px;
    border: 1px solid doveGray;
    z-index: 1;

    > select {
      margin: 0;
      font-family: fontFamilySelect;
      appearance: none;
      font-size: 14px;
      color: black;
      border: 0;
      box-shadow: none;
      line-height: 18px;
      background-color: transparent;
      width: 100%;
      padding: 10px 30px 10px 8px;
    }

    > svg {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -12px;
      width: 24px;
      height: 24px;
      z-index: selectContainerSvgLayer;
    }
  }

  .selectContainer--sm {
    composes: selectContainer;

    > select {
      padding: 4px 30px 4px 4px;
    }

    > svg {
      right: 8px;
      top: 50%;
      margin-top: -8px;
      width: 16px;
      height: 16px;
    }
  }
}
