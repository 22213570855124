@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xl, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value base from "@/modules/styling/zIndex.module.css";
@value NAVIGATION_PLACEHOLDER_WIDTH_PX from "@/components/SellerHub/Navigation/styles.module.css";

@layer components {
  .childrenWrapper {
    padding: xl;
    position: relative;
    flex: 1;
    width: calc(100% - NAVIGATION_PLACEHOLDER_WIDTH_PX);

    @media breakpointMdDown {
      padding: xl md 0;
      width: 100%;
    }
  }

  .contentContainer {
    display: flex;
    overflow-x: clip;
    z-index: base;
    position: relative;

    @media breakpointMdDown {
      display: block;
      padding: 0 0 40px;
    }
  }
}
