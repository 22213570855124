@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value red from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .pageWrap {
    margin: 0 auto;
    width: 100%;
    max-width: 680px;
    padding-left: md;
    padding-right: md;
  }

  .errorContents {
    width: 300px;
    text-align: center;
    align-items: center;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    padding-top: 120px;

    h3 {
      margin-bottom: md;
    }
  }

  .reload {
    margin-top: md;

    p {
      color: red;
    }
  }
}
