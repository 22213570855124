@value black, white, lightGrey2  from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value xs, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .messageButton {
    cursor: pointer;
    font-size: 16px;
    font-family: fontFamilyBody;
    font-weight: normal;
    background-color: transparent;
    color: black;
    border: none;
    line-height: 1.25;
    align-items: center;
    display: inline-flex;
    padding: 0;

    &:hover {
      background: transparent;
      border: none;
      text-decoration: underline;
    }

    &:disabled {
      background: transparent;
      border: none;
      cursor: not-allowed;
      background-color: white;
      color: lightGrey2;
    }

    @media breakpointMdDown {
      margin-right: xs;
    }
  }
}
