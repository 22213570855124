@value xs, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value midGrey, lightGrey1, lightGrey2, red, black, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value inputLabel from "@/modules/styling/zIndex.module.css";

@layer components {
  .wrapper {
    position: relative;
    align-items: center;
    width: var(--width);
    margin-bottom: md;
    z-index: 0;
  }

  .inputContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: 56px;
    outline: #acacac solid var(--outline-width);
    border-radius: borderRadiusMedium;
  }

  /* ORDER OF THESE THREE STYLES IS CRITICAL, DO NOT CHANGE */
  .inputContainerError {
    outline-color: red;
  }

  /* ORDER OF THESE THREE STYLES IS CRITICAL, DO NOT CHANGE */
  .inputContainerFocused {
    outline-color: black;
  }

  /* ORDER OF THESE THREE STYLES IS CRITICAL, DO NOT CHANGE */
  .inputContainerDisabled {
    background: lightGrey1;
  }

  .input {
    font-size: 16px;
    font-family: fontFamilyBody;
    width: calc(100% - 2px);
    margin: 0 1px;
    outline: none;
    border: none;
    padding-top: md;
    padding-left: md;
    padding-right: md;

    &:disabled {
      opacity: 40%;
    }

    &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    &:-webkit-autofill {
      /* Removes light blue background shown inside the input when autofill is triggered */
      box-shadow: 0 0 0 1000px white inset;
    }
  }

  .inputPaddingLeft {
    padding-left: 48px;
  }

  .label {
    position: absolute;
    font-family: fontFamilyBody;
    color: midGrey;
    transition: all 0.2s ease-in-out;
    transform: translate3d(16px, 20px, 0);
    font-size: 14px;
    left: var(--label-left);
    z-index: inputLabel;

    &:hover {
      cursor: text;
    }
  }

  .labelFocused {
    font-size: 12px;
    transform: translate3d(16px, 8px, 0);
  }

  .labelDisabled {
    color: lightGrey2;
    opacity: 0.4;
  }

  .leadingIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    left: md;
  }

  .trailingIconsWrapper {
    padding-top: xs;
    position: absolute;
    align-items: center;
    right: 0;
    display: flex;
    gap: xs;
    top: 50%;
    transform: translateY(-50%);
    margin-right: md;

    &:hover {
      cursor: pointer;
    }
  }

  .textContainer {
    display: flex;
    justify-content: var(--justify-content);
    margin-bottom: xs;
  }

  .assistiveText {
    margin-top: xs;
  }

  .errorText {
    margin: xs 0 0 md;
    color: red;
  }

  .eraseIconContainer {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .eraseIcon {
    color: midGrey;
  }
}
