@value lightGrey1, grey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .uploadPageHeader {
    margin-bottom: 20px;
    padding-top: md;
    display: flex;
    flex-direction: column;
    gap: xs;
  }

  .disclaimer {
    border-bottom: 1px solid lightGrey1;
    color: grey;
    padding-bottom: sm;
  }
}
