@value breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value currencyDropdownActionMenuChildren from "@/modules/styling/zIndex.module.css";

@layer components {
    .triggerButton {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        font-family: fontFamilyBody;
        font-weight: normal;
        font-size: 0.875rem; /* = 14px */
        line-height: 1.5;
        cursor: pointer;
    }

    .globeIcon {
        flex: 1 0;
    }

    .selectedCurrencyWrapper {
        margin-left: sm;
        white-space: nowrap;

        @media breakpointSmDown {
            display: none;
        }
    }

    .arrowIcon {
        flex: 1 0;
        margin-left: sm;
    }

    .menuItemOption {
        display: flex;
        justify-content: space-between;
        line-height: 1.5;
    }

    .menuItemOption--selected {
        font-weight: bold
    }

    .actionMenu {
        z-index: currencyDropdownActionMenuChildren;
        max-height: 300px;
        overflow-y: auto;
    }
}