@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md, sm, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container {
    border-bottom: 1px solid lightGrey2;
    display: flex;
    flex-direction: column;
    gap: sm;
    padding: md xl;
    text-align: center;
  }
}
