@value lightGrey1, red, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value lg, md, sm, xl, xs from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown, breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .headerWrapper {
    flex: 1;
    text-align: center;
    line-height: 0;

    /** Ensure the money image is centred by offsetting the width against the modal close button */
    margin-left: lg;
  }

  .container {
    padding: 0 xl xl;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .heading {
    margin-bottom: sm;
  }

  .primaryInstructions {
    margin-bottom: lg;
  }

  .desktopActionContainer {
    @media breakpointSmDown {
      display: none;
    }
  }

  .mobileActionContainer {
    @media breakpointSmUp {
      display: none;
    }
  }

  .downloadAppButton {
    display: inline-block;
    margin: 0 auto;
  }

  .depopLogo {
    width: 88px;
    display: block;
    padding-top: xs;
    margin: 0 sm;
  }

  .title {
    display: flex;
    justify-content: center;
  }

  .card {
    width: 360px;
    border-radius: 12px;
    border: none;
    height: 230px;
    margin: md auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: md;
    color: white;
    text-align: left;
    background: red;

    @media breakpointSmDown {
      width: 100%;
    }
  }

  .loading {
    background: lightGrey1;
  }

  .error {
    opacity: 50%;
  }

  .balance {
    color: white;
    padding-bottom: md;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);
  }

  .available {
    color: white;
    margin-top: md;
  }
}
