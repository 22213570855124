@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value searchResultsStackingContext from "@/modules/styling/zIndex.module.css";
@value breakpointSmUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value black, blue from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .seeMoreText {
    border: none;
    padding: 0;
    color: blue;
    background: none;
    text-decoration: none;
  }

  .seeMoreText:hover,
  .seeMoreText:focus {
    background: none;
    text-decoration: underline;
  }

  .translucentHoverOverlay {
    height: 100%;
    background: black;
    opacity: 0.3;
  }

  .hoverImage {
    filter: brightness(0.7);
  }

  .attributesSkeleton {
    display: none;

    @media breakpointMdUp {
      display: block;
    }
  }
}

@layer overrides {
  /* This class needs to be in "overrides" so that they are applied to the placeholder skeleton */
  .productGrid {
    position: relative;
    z-index: searchResultsStackingContext;
    display: grid;
    gap: md;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media breakpointSmUp {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media breakpointMdUp {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }
}
