@value midGrey, gallery, alto, black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .brandFilterDropdownContents {
    @media breakpointLgUp {
      max-height: 300px;
      overflow: hidden;
    }
  }

  .brandFilterCheckbox {
    padding: sm 20px;
    border-top: 1px solid gallery;
    font-family: var(--font-GtAmericaExtended);

    span {
      font-weight: var(--font-weight);
    }
  }

  .brandFilterContent {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  .brandFilterDropdownHeading {
    padding: md 20px;
    font-size: 12px;
    color: midGrey;
    font-family: var(--font-GtAmericaExtended);
  }

  .brandFilterSearchContainer {
    position: relative;
    border-top: 1px solid alto;
    border-bottom: 1px solid alto;
    display: flex;
    align-items: center;
    gap: sm;
    padding-left: md;

    &:focus-within {
      background-color: gallery;
    }

    @media breakpointLgUp {
      border-top: none;
    }
  }

  .brandFilterSearchInput {
    height: 48px;
    border: none;
    border-radius: 0;
    width: 100%;
    font-family: var(--font-GtAmericaExtended);
    background-color: unset;

    &::placeholder {
      font-size: 14px;
      color: black;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .brandFilterNoResultsText {
    padding: md;
    font-size: 12px;
  }

  .listContainer {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;

    @media breakpointLgUp {
      max-height: 300px;
    }
  }
}
