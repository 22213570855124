@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value doveGray from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value inputGroupLabel, searchBarInputActionLayer from "@/modules/styling/zIndex.module.css";

@layer component {
  .inputWrapper {
    position: relative;
    line-height: 40px;

    @media breakpointMdDown {
      line-height: 48px;
    }
  }

  .label {
    color: doveGray;
    font-size: 12px;
    margin-bottom: sm;
    transition: 0.2s ease-in-out 0s;
    cursor: text;
    left: 8px;
    transform-origin: 0;
    pointer-events: none;
    z-index: inputGroupLabel;
  }
}
