@value white, silverChalice, black_r, black_g, black_b from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown, breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sellingItemImageOverlay from "@/modules/styling/zIndex.module.css";

@layer components {
    .container {
        display: flex;
        align-items: center;
        position: relative;
    }

    .imagePlaceholder {
        width: 80%;
        height: 80%;
        background-color: silverChalice;
        position: absolute;
        right: calc(sm * (-1)); /* Using -sm directly causes a mismatched prop value error */
        border-radius: 2px;
    }

    .imagePlaceholder--with-mobile-view {
        @media breakpointSmDown {
            width: 41px;
            height: 41px;
            right: -6px;
        }
    }

    .imageBehind {
        position: absolute;
        border-radius: 2px;
        right: calc(md * var(--index) * -1); /* Using minus sign directly causes parsing issues */
        width: calc(100% * (1 - var(--index) * 1.5 / 10));
        height: calc(100% * (1 - var(--index) * 1.5 / 10));
    }

    .pictureWrapper {
        border-radius: 2px;
        overflow: hidden;
        position: relative;
        width: 88px;
        height: 88px;
    }

    .pictureWrapper---customized {
        width: var(--custom-size);
        height: var(--custom-size);
    }

    .pictureWrapper--smaller-dimensions {
        width: 80px;
        height: 80px;
    }

    .pictureWrapper--smaller-dimensions-customized {
        width: calc(0.9 * var(--custom-size));
        height: calc(0.9 * var(--custom-size));
    }

    .pictureWrapper--with-mobile-view {
        @media breakpointSmDown {
            width: 56px;
            height: 56px;

            &:nth-child(2n) {
                left: 2px;
            }
        }
    }

    .pictureWrapper--with-mobile-view-smaller-dimensions {
        @media breakpointSmDown {
            width: 52px;
            height: 52px;

            &:nth-child(2n) {
                left: 2px;
            }
        }
    }

    .imageOverlay {
        position: absolute;
        inset: 0;
        display: flex;
        z-index: sellingItemImageOverlay;
        align-items: center;
        justify-content: center;
        background-color: rgba(black_r, black_g, black_b, 0.3);
        object-fit: cover;
    }

    .overlayText {
        color: white;
        text-align: center;
        font-size: 10px;
        overflow-wrap: anywhere;

        @media breakpointSmUp {
            font-size: 12px;
        }
    }
}