@value breakpointSmUp, breakpointSmDown, breakpointMdUp, breakpointLgUp, breakpointXlDown, breakpointXlUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sellerLandingContainerHeroContent from "@/modules/styling/zIndex.module.css";

@layer components {
  .insiderHeroImageMobile {
    height: 60%;
    object-fit: fill;
    width: 100%;
    position: absolute;
    display: block;

    @media breakpointSmUp {
      display: none;
    }
  }

  .insiderHeroSection {
    height: 100%;
  }

  .insiderHeroContent {
    height: 100%;
    display: flex;
    max-width: 350px;
    gap: xl;
    z-index: sellerLandingContainerHeroContent;
    position: relative;
    flex-direction: column;
    margin-bottom: 300px;
    justify-content: center;

    @media breakpointMdUp {
      margin-bottom: 0;
      max-width: 350px;
    }

    @media breakpointLgUp {
      max-width: 600px;
    }
  }

  .insiderHeroTitle {
    color: white;
    font-size: xl;

    @media breakpointMdUp {
      font-size: lg;
    }

    @media breakpointXlUp {
      font-size: xl;
    }
  }

  .insiderHeroSubtitle {
    color: white;

    @media breakpointXlDown {
      max-width: 400px;
    }
  }

  .heroImageSecondary {
    height: 40%;
    width: 100%;
    max-height: 40%;
    position: absolute;
    bottom: 0;
    display: block;
    object-fit: cover;

    @media breakpointSmUp {
      max-height: 100%;
      right: 0;
      width: 100%;
    }

    @media breakpointMdUp {
      height: 100%;
      right: 0;
      width: 50%;
    }

    @media breakpointLgUp {
      width: auto;
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: lg;

    &:not(:last-child) {
      margin-bottom: 72px;
    }

    @media breakpointMdUp {
      flex-direction: row;
      gap: md;
    }
  }

  .cardReverse {
    @media breakpointMdUp {
      flex-direction: row-reverse;
    }
  }

  .copyImage {
    display: block;
    margin-bottom: 0;
    width: 100%;
    height: 100%;

    @media breakpointMdUp {
      width: unset;
    }
  }

  .cardTitle {
    @media breakpointSmDown {
      font-size: lg;
    }
  }
}
