@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value yellow, white, black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value productCardSoldText, productCardBoostedTag, productCardOverlayTags from "@/modules/styling/zIndex.module.css";
@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .translucentHoverOverlay {
    height: 100%;
    background: black;
    opacity: 0.3;
  }

  .productAttributesContainer {
    display: none;
    margin: sm xs 0;

    @media breakpointMdUp {
      display: flex;
      justify-content: space-between;
    }
  }

  .gradientOverlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(38, 38, 38, 0.3);
    z-index: productCardOverlayTags;
    position: absolute;
    inset: 0;
    pointer-events: none;
  }

  .soldText {
    color: yellow;
    text-transform: uppercase;
    z-index: productCardSoldText;
  }

  .boostedTagText {
    background-color: white;
    border-radius: xs;
    position: absolute;
    top: sm;
    right: sm;
    z-index: productCardBoostedTag;
    color: black;
    padding: 0 2px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  }

  .boostedTagIcon {
    vertical-align: text-top;
  }
}
