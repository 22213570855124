@value outfitFeedItemReactionFlame from "@/modules/styling/zIndex.module.css";

@layer components {
  .animatedFlame {
    position: absolute;
    z-index: outfitFeedItemReactionFlame;
    animation-timing-function: cubic-bezier(0, 0.5, 1, 1);
    animation-duration: 900ms;
  }

  /* Like Reaction animation */

  @keyframes flameRiseUp1 {
    0% {
      transform: translate(0, 0);
    }

    75% {
      opacity: 1;
    }

    100% {
      transform: translate(30px, -440px) scale(1.5) rotate(-18deg);
      opacity: 0;
    }
  }

  @keyframes flameRiseUp2 {
    0% {
      transform: translate(0, 0);
    }

    75% {
      opacity: 1;
    }

    100% {
      transform: translate(-15px, -413px) rotate(12deg) scale(0.97);
      opacity: 0;
    }
  }

  @keyframes flameRiseUp3 {
    0% {
      transform: translate(0, 0);
    }

    75% {
      opacity: 1;
    }

    100% {
      transform: translate(-33px, -385px) rotate(-18deg) scale(1.03);
      opacity: 0;
    }
  }

  @keyframes flameRiseUp4 {
    0% {
      transform: translate(0, 0);
    }

    75% {
      opacity: 1;
    }

    100% {
      transform: translate(23px, -358px) rotate(-22deg);
      opacity: 0;
    }
  }

  @keyframes flameRiseUp5 {
    0% {
      transform: translate(0, 0);
    }

    75% {
      opacity: 1;
    }

    100% {
      transform: translate(-24px, -330px) rotate(-18deg);
      opacity: 0;
    }
  }

  @keyframes flameRiseUp6 {
    0% {
      transform: translate(0, 0);
    }

    75% {
      opacity: 1;
    }

    100% {
      transform: translate(16px, -303px) rotate(4deg);
      opacity: 0;
    }
  }

  @keyframes flameRiseUp7 {
    0% {
      transform: translate(0, 0);
    }

    75% {
      opacity: 1;
    }

    100% {
      transform: translate(3px, -275px) rotate(-22deg);
      opacity: 0;
    }
  }

  .animatedFlamesContainer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .animatedFlame:nth-child(1) {
      animation-name: flameRiseUp1;
    }

    .animatedFlame:nth-child(2) {
      animation-name: flameRiseUp2;
    }

    .animatedFlame:nth-child(3) {
      animation-name: flameRiseUp3;
    }

    .animatedFlame:nth-child(4) {
      animation-name: flameRiseUp4;
    }

    .animatedFlame:nth-child(5) {
      animation-name: flameRiseUp5;
    }

    .animatedFlame:nth-child(6) {
      animation-name: flameRiseUp6;
    }

    .animatedFlame:nth-child(7) {
      animation-name: flameRiseUp7;
    }
  }
}
