@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .wrapper {
    margin: md 0 0;
  }

  .switch {
    margin-bottom: md;
  }
}
