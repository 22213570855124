@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, lightGrey1, midGrey, red, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .messageInputContainer {
    position: relative;
    display: flex;
    background: white;
    border-top: 1px solid lightGrey1;
    border-right: 1px solid lightGrey1;
  }

  .messageInputTextArea {
    padding: md;
    padding-right: 48px;
    width: 100%;
    height: 49px;
    min-height: 24px;
    color: black;
    border: none;
    resize: none;
    font-family: var(--font-GtAmericaExtended);
    font-size: 14px;

    &::placeholder {
      color: midGrey;
    }

    &:focus {
      outline: 0;
    }
  }

  .readOnlyContainer {
    composes: messageInputTextArea;
    text-align: center;
  }

  .messageInputTextAreaWithLengthWarning {
    padding-right: 148px;

    @media breakpointLgUp {
      padding-right: 132px;
    }
  }

  .messageInputActionContainer {
    position: absolute;
    right: md;
    bottom: sm;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .messageInputLengthWarning {
    color: midGrey;
    font-size: 14px;
  }

  .messageInputLengthExceededWarning {
    color: red;
  }

  .messageInputSubmitButton {
    border: none;
    background: unset;
    cursor: pointer;

    &[disabled] {
      cursor: default;
    }
  }

  .messageInputErrorContainer {
    position: absolute;
    width: 100%;
    transform: translateY(-100%);
  }
}
