@value black, white, gallery, yellow, lightGrey2, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value stickyTabs from "@/modules/styling/zIndex.module.css";

@layer components {
  .tabsContainer {
    list-style: none;
    padding: 0;
    margin: 0;
    position: sticky;
    top: 0;
    background-color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid gallery;
    overflow-x: auto;
    z-index: stickyTabs;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tab {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
    display: flex;
    white-space: nowrap;
  }

  .tabInput {
    position: absolute;
    opacity: 0;

    &:focus {
      + label {
        box-shadow: 0 0 0 3px yellow;
        z-index: 1;
      }
    }
  }

  .labelInput {
    cursor: var(--cursor);
    color: var(--colour);
    text-align: center;
    padding: sm md;
    box-sizing: border-box;

    span {
      display: inline-flex;
      color: inherit;
      font-weight: var(--font-weight);
    }
  }

  .labelInput--active {
    &:hover {
      color: midGrey;
    }
  }

  .suffixWrapper {
    display: flex;
    margin: 0 sm;
  }
}
