@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp, breakpointLgUp, breakpointLgDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value simpleDrawerWrapperMobileLayer from "@/modules/styling/zIndex.module.css";

@layer components {
  .filterWrapper {
    padding-top: md;
    background-color: white;

    @media breakpointLgUp {
      padding-top: md;
    }
  }

  .activeFilterMobileContainer {
    display: flex;

    @media breakpointLgUp {
      display: none;
    }
  }

  .desktopSellingFilters {
    display: none;

    @media breakpointSmUp {
      display: block;
    }
  }
}

@layer overrides {
  .mobileSellingFilters {
    display: flex;
    flex-direction: column;
    gap: md;

    @media breakpointLgUp {
      display: none;
    }
  }

  .mobileSellingFiltersDrawer {
    @media breakpointLgDown {
      width: 100%;
      max-width: 100%;
      height: 100vh;
      top: 0;
      z-index: simpleDrawerWrapperMobileLayer;
    }
  }
}
