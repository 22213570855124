@value xs from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value boulder from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .container {
    display: flex;
    align-items: center;
    gap: xs;
  }

  .signal {
    display: flex;
    align-items: center;
  }

  .visualSeparator {
    color: boulder;
  }
}