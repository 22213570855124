@value xl, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white, black, midGrey_r, midGrey_g, midGrey_b from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .floatingControls {
    position: absolute;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    gap: xl;
    background-color: white;
    padding: 12px md;
    border-radius: 29px;
    box-shadow: 0 8px md 0 rgba(midGrey_r, midGrey_g, midGrey_b, 0.3);
  }

  .canvasControl {
    padding: 0;
    background-color: white;
    border: none;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      background-color: white;
    }
  }

  .canvasControlIcon {
    color: black;
  }
}
