@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value PLACEHOLDER_ROW_HEIGHT from "@/modules/styling/global.module.css";

@layer components {
    .row {
        height: PLACEHOLDER_ROW_HEIGHT;

        &:nth-child(odd) {
            background-color: lightGrey1;
        }
    }

    .cell {
        /* The default is line-height: normal, which evaluates to 1.2 and prevents vertical centering. */
        line-height: 100%;
        padding: md;
    }

    .rectangle {
        margin-top: sm;
    }
}