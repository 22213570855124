@value breakpointMdUp, breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value white, grey, midGrey, lightGrey2, black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusSmall, borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value normal from "@depop/web-ui-kit/theme/default/lineHeight.module.css";
@value productCardOverlayTags from "@/modules/styling/zIndex.module.css";

@layer search {
  .container {
    margin: 0 auto;
    width: 100%;
    padding: 0 md;
    flex: 1 1 0%;
    margin-bottom: calc(2 * xl);

    @media breakpointLgUp {
      max-width: 1280px;
      padding: 0 xl;
    }
  }

  .header {
    display: flex;
    flex-flow: column wrap;
    margin: lg 0;
    gap: md;

    @media breakpointMdUp {
      margin: lg 0 0;
    }
  }

  .searchHeaderTopContent {
    width: 100%;
    border-bottom: 1px solid lightGrey2;
    padding-bottom: md;
    margin-bottom: sm;
    display: flex;
    flex-direction: column;
    gap: lg;
  }
}

@layer components {
  .searchHeaderText {
    display: flex;
    align-items: flex-end;
    gap: sm;
  }

  .searchQuerySecondary {
    margin-bottom: xs;
  }

  .productCardListItem {
    list-style-type: none;
  }

  @keyframes heartbeat {
    0%,
    100% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.2);
    }
  }

  .noResultsFallbackContainer {
    max-width: 720px;
    margin-top: lg;
    margin-bottom: lg;
    border: 1px solid black;
    padding: md;
    padding-left: lg;
    text-align: left;
    display: flex;
    align-items: center;
    border-radius: borderRadiusSmall;
  }

  .noResultsFallbackTextContainer {
    margin-left: lg;
  }

  .noResultsFallbackTitle {
    font-weight: bold;
  }
}
