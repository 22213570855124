@value lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value HEADER_HEIGHT_PX, HEADER_HEIGHT_MOBILE_PX  from "@/modules/styling/global.module.css";

@layer overrides {
  .container {
    max-width: 480px;
    padding-bottom: calc(lg * 2);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .container__native_view {
    composes: container;
    min-height: calc(100vh - HEADER_HEIGHT_PX);

    @media (max-width: 768px) {
      min-height: calc(100vh - HEADER_HEIGHT_MOBILE_PX);
    }
  }
}
