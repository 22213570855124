@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value doveGray, red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusSmall from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .priceFilterDropdownContents {
    padding: 0;

    @media breakpointLgUp {
      max-height: 300px;
      padding: md;
    }
  }

  .priceFilterInputRow {
    margin: xl calc(2 * xl);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media breakpointLgUp {
      margin: 0;
    }
  }

  .priceFilterInputContainer {
    display: flex;
    flex-direction: column;
  }

  .priceFilterInputLabel {
    margin-bottom: xs;
    font-size: 12px;
    line-height: 1;
    margin-left: 2px;
    font-family: var(--font-GtAmericaExtended);
  }

  .priceFilterInput {
    height: 40px;
    width: 80px;
    border: solid 1px doveGray;
    border-radius: borderRadiusSmall;
    font-size: 14px;
    text-align: center;
    font-family: var(--font-GtAmericaExtended);
    margin-left: 2px;

    &::placeholder {
      color: doveGray;
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }

  .priceFilterInputError {
    border-color: red;
  }

  .priceFilterHr {
    flex-grow: 1;
    height: 1px;
    margin-left: xl;
    margin-right: xl;
    margin-top: lg;
  }

  .priceFilterErrorMessage {
    color: red;
    margin-top: sm;
  }

  .priceFilterButtonRow {
    display: none;

    @media breakpointLgUp {
      display: flex;
      justify-content: space-between;
      margin-top: lg;
      gap: md;
    }
  }

  .priceFilterButton {
    flex: 1;
    padding: 0;
    height: 32px;
    font-size: 12px;
  }
}
