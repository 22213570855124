@value lightGrey2, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value sidebarMenuMobileDropdown, sidebarMenuMobileBackdrop from "@/modules/styling/zIndex.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value NAVIGATION_MAX_WIDTH: 240px;
@value MOBILE_DROPDOWN_HEIGHT: 56px;

@layer components {
  .navigation {
    width: NAVIGATION_MAX_WIDTH;
    list-style: none;
    margin: 0;
  }

  .mobileBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(38, 38, 38, 0.8);
    z-index: sidebarMenuMobileBackdrop;
    visibility: visible;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }

  .mobileDropdown {
    position: relative;
    z-index: sidebarMenuMobileDropdown;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-bottom: 1px solid lightGrey2;
    width: 100%;
    height: MOBILE_DROPDOWN_HEIGHT;
    padding-left: lg;
    padding-right: lg;

    span {
      font-size: 0.875rem;
      line-height: 1.5;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  @keyframes dropdownMenuAnimation {
    0% {
      opacity: 0;
      transform: translateY(-8px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .mobileDropdownMenuContainer {
    position: relative;
    width: 100%;
    display: inline-block;
    overflow-y: scroll;
    z-index: sidebarMenuMobileDropdown;
    background: white;
    transition: all 0.2s ease-in-out;
    animation: dropdownMenuAnimation 0.2s;
    animation-fill-mode: forwards;
  }
}
