@value lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, xl, xs from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .container,
  .userContainer {
    display: flex;
    align-items: center;
  }

  .container {
    justify-content: space-between;
    border-bottom: 1px solid lightGrey2;
    padding: 0 xl sm xl;
  }

  .userContainer {
    margin-bottom: xs;
  }

  .name {
    margin-left: sm;
  }

  .fullname {
    font-size: 14px;
  }

  .username {
    font-size: 12px;
  }
}
