@value popoverLayer from "@/modules/styling/zIndex.module.css";
@value white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusSmall from "@depop/web-ui-kit/theme/default/border.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .wrapper {
    z-index: popoverLayer;
    overflow: hidden;
    filter: drop-shadow(0 2px 8px rgba(116, 116, 116, 0.3));
  }

  .innerWrapper {
    background-color: white;
    border-radius: borderRadiusSmall;
    overflow: hidden;
    cursor: default;

    /* Create a new stacking context - Fixes an issue on safari where the border radius wasn't showing */
    isolation: isolate;
  }
}
