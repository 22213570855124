@value xs, sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value gallery from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .categoryFilterDropdownContents {
    overflow-y: auto;

    @media breakpointLgUp {
      max-height: 500px;
      padding: 0;
    }
  }

  .categoryCheckbox {
    padding: 0 calc(md + xs) 0 xl;
    border-top: 1px solid gallery;
  }

  .subcategoryCheckbox {
    padding: 0 lg 0 md;
    border-top: 1px solid gallery;

    @media breakpointLgUp {
      &:first-of-type {
        border-top: none;
      }
    }
  }
}
