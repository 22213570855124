@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value black, blue, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .navigationLink {
    display: flex;
    min-width: 44px;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
    position: relative;

    @media breakpointLgUp {
      margin-right: 4px;
    }
  }

  .navigationLink--active {
    color: blue;
  }
}
