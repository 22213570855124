@value white, lightGrey1, lightGrey2, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp, breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value HEADER_HEIGHT_PX, HEADER_HEIGHT_MOBILE_PX  from "@/modules/styling/global.module.css";

@layer components {
  .container {
    background-color: lightGrey1;
    display: block;
    height: 100%;
    flex-grow: 1;
    overflow-y: scroll;

    @media breakpointLgUp {
      display: grid;
      grid-template-columns: 400px 2fr minmax(280px, 1fr);
      overflow: hidden;
    }
  }

  .positionedPanel {
    position: absolute;
    display: var(--mobileDisplay);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    padding-top: HEADER_HEIGHT_MOBILE_PX;
    flex-direction: column;

    @media breakpointMdUp {
      padding-top: HEADER_HEIGHT_PX;
    }

    @media breakpointLgUp {
      display: flex;
      position: relative;
      height: 100%;
      min-height: 100%;
      padding-top: 0;
    }
  }

  .firstPositionedPanel {
    border-right: 1px solid lightGrey2;
  }

  .conversationDetailsPanel {
    background-color: lightGrey1;
  }

  .conversationDetails {
    overflow-y: auto;
  }

  .messagesEmptyStateContainer {
    @media breakpointLgUp {
      display: flex;
      flex-direction: column;
      padding: calc(lg + xl) md 0;
      background-color: white;
      align-items: center;
      border-right: 1px solid lightGrey2;
    }
  }

  .messagesEmptyStateIcon {
    color: midGrey;
  }

  .messagesEmptyStateHeading {
    margin-top: sm;
  }

  .messagesEmptyStateText {
    margin-top: sm;
  }
}
