@value breakpointSmUp, breakpointMdUp, breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .bannerImageWrapper {
    padding: 0;
    margin-top: calc(sm * -1); /* we need to use negative margins as there are padding values being set by the layout component styles that we aren't easily able to override */
    margin-left: calc(md * -1);
    margin-right: calc(md * -1);

    @media breakpointSmUp {
      width: 100%;
      margin: 0;
    }
  }

  .bannerImage {
    background-image: var(--mobileImage);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: var(--mobileHeight);

    @media breakpointSmUp {
      background-image: var(--desktopImage);
      height: var(--desktopHeight);
      border-radius: borderRadiusMedium;
    }
  }
}
