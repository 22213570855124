@value breakpointSmUp, breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .title {
    margin: 0 0 8px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
    padding: 24px;

    @media breakpointSmUp {
      max-width: 340px;
    }

    @media breakpointSmDown {
      padding: 16px;
    }
  }

  .cta {
    display: flex;
    margin: 16px auto 0;
  }
}
