@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value black, green, white, lightGrey2, midGrey, governorBay, red from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .orderSummaryContainer {
    position: sticky;
    top: md;
    min-height: 146px;
    display: flex;
    flex-direction: column;
    gap: md;
  }

  .accordionToggleButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: unset;
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 0;
  }

  .accordionTitle {
    display: flex;
    align-items: center;
    gap: sm;
  }

  .accordionContent {
    display: flex;
    flex-direction: column;
    margin-top: md;
    gap: md;
  }

  .title {
    @media breakpointMdUp {
      display: none;
    }
  }

  .sellerContainer {
    display: flex;
    gap: sm;
    align-items: center;
  }

  .sellerInfo {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex: 1;
    min-width: 0;
  }

  .sellerUsername {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }

  .feedback {
    margin: 0;
  }

  .products {
    display: flex;
    flex-direction: column;
    gap: md;
  }

  .wrapper {
    display: flex;
    align-items: center;
    gap: sm;
  }

  .imageContainer {
    max-width: 80px;

    /* not sure why extra max height required here? was rendinering as 83px */
    max-height: 80px;
    border-radius: borderRadiusMedium;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
  }

  .content {
    flex: 1;
    min-width: 0;
  }

  .description {
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media breakpointSmUp {
      line-clamp: 1;
      -webkit-line-clamp: 1;
    }
  }

  .size {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: sm;
  }

  .price {
    flex-direction: column;
    align-items: flex-end;

    p {
      padding: 0;
      font-weight: normal;
      font-size: 16px;
    }
  }

  .discountedPrice {
    p {
      :first-child {
        font-size: 14px;
      }

      :last-child {
        font-size: 16px;
      }
    }
  }

  .breakdownWrapper {
    border-top: 1px solid lightGrey2;
    border-bottom: 1px solid lightGrey2;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin: md 0;
  }

  .row--tax {
    margin: md 0;
  }

  .row--total {
    display: flex;
    justify-content: space-between;
  }

  .breakdownText {
    display: flex;
    align-items: center;
    font-weight: 400;
  }

  .breakdownText--green {
    color: green;
  }

  .breakdownText--grey {
    color: midGrey;
  }

  .breakdownTextSmall {
    display: flex;
    align-items: center;
    color: black;
  }

  .icon {
    display: flex;
    margin-left: sm;
  }

  .expandButton {
    align-items: center;
    display: inline-flex;
    margin-left: sm;
    border: 0;
    padding: 0;
    background: 0;
    color: black;
    cursor: pointer;

    svg {
      transition: 0.2s ease transform;
      transform: rotate(0deg);
    }
  }

  .expanded {
    svg {
      transform: rotate(180deg);
    }
  }

  .expandableRow {
    h3 {
      margin-bottom: md;
    }
  }

  .taxLearnMoreLink {
    color: governorBay;
    text-decoration: none;
  }

  .totalRow {
    display: flex;
    justify-content: space-between;
  }

  .errorText {
    color: red;
  }
}
