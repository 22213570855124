@value breakpointMdUp, breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, blue, white, midGrey, a11yYellow from "@depop/web-ui-kit/theme/default/colors.module.css";
@value depopEditPrice from "@/modules/styling/zIndex.module.css";
@value fontFamilySplash from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .productListContainer {
    display: grid;
    list-style: none;
    grid-template-columns: repeat(2, 1fr);
    gap: sm;

    @media breakpointMdDown {
      li:nth-child(5) {
        grid-area: 3 / 1 / 5 / 3;
      }
    }

    @media breakpointMdUp {
      grid-template-columns: repeat(5, 1fr);
      gap: md;

      li:nth-child(4) {
        grid-area: 1 / 4 / 3 / 6;
      }
    }
  }

  .overlayContainer {
    position: relative;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      display: block;
      inset: 0;
      object-fit: cover;
      background: linear-gradient(
        0deg,
        rgba(38, 38, 38, 0.6) -10%,
        rgba(38, 38, 38, 0) 100.34%
      );
    }
  }

  .attributesContainer {
    position: absolute;
    bottom: sm;
    left: sm;
    z-index: depopEditPrice;
  }

  .attributes {
    color: white;
    margin: 0;
    line-height: 1;
    font-family: var(--font-GtAmericaExtended);
  }

  .size {
    font-size: 14px;
  }

  .price {
    p {
      color: white;
      margin: 0;
      line-height: 1;
      font-family: var(--font-GtAmericaExtended);

      &:last-of-type {
        font-weight: bold;
      }
    }
  }

  .seeMore {
    span {
      color: blue;
      font-weight: normal;
      font-size: 14px;
      font-family: var(--font-GtAmericaExtended);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .modalContainer {
    padding: 0 sm sm sm;

    @media breakpointMdUp {
      padding: 0 xl xl xl;
    }
  }

  .productListModalContainer {
    list-style: none;
    display: grid;
    gap: sm;
    grid-template-columns: repeat(2, 1fr);

    @media breakpointMdUp {
      grid-template-columns: repeat(6, 1fr);
      gap: md;
    }
  }

  .productAttributesModalContainer {
    margin: sm 0 0 xs;
  }

  .placeholderTitle {
    width: 50%;
    margin-bottom: sm;

    @media breakpointMdUp {
      width: 25%;
    }
  }

  .placeholderSubtitle {
    width: 50%;
    margin-bottom: lg;

    @media breakpointMdUp {
      width: 25%;
    }
  }

  .placeholderTile {
    aspect-ratio: 1;
  }
}
