@value lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .container {
    display: flex;
    flex-grow: 1;
    min-height: calc(100vh - 120px);
  }

  .content {
    display: flex;
    width: 50%;
    padding: 20px;
    align-items: flex-start;
    flex-flow: column wrap;
  }

  .placeholder {
    width: 50%;
    background-color: lightGrey1;
  }

  .pageContent {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 40px;
  }

  .stepContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
  }
}
