@value md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmDown, breakpointSmUp, breakpointLgDown, breakpointXlDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value white, alto, black, gallery from "@depop/web-ui-kit/theme/default/colors.module.css";
@value HEADER_HEIGHT_MOBILE_PX from "@/modules/styling/global.module.css";

@layer components {
  .wrapper {
    background-color: white;
  }

  .receiptsHeader {
    display: flex;
    flex-direction: row;
    padding: 0 xl 0;
    align-items: center;

    @media breakpointLgDown {
      padding-bottom: md;
    }
  }

  .title {
    @media breakpointSmDown {
      text-align: center;
      padding: 0;
      position: relative;
      margin: auto;
    }
  }

  .mobileFilterButton {
    display: none;

    @media breakpointSmDown {
      display: inherit;
    }
  }

  .flexContainer {
    display: flex;

    @media breakpointXlDown {
      padding: 0;
    }
  }

  .shippingStatusMobileContainer {
    background-color: white;
    width: 100%;
    padding: md;
    display: flex;
    flex-direction: column;
    height: 90%;

    @media breakpointSmUp {
        display: none;
      }
  }

  .subtitle{
    display: block;
    position: static;
    text-align: center;
    padding-bottom: md;
  }

  .closeButton{
    position: absolute;
    right: md;
    top: md;
    margin: 0;
    line-height: 1;
  }

  .buttonGroup{
    padding: 0;

    & button {
      border-radius: 0;
      border-right: none;

      &:hover {
        border-right: none;
      }

      &:last-of-type {
        border-bottom: 1px solid alto;
        border-right: none;
        border-radius: 0;
      }

      &:first-of-type {
        border-radius: 0;
      }

      &[disabled] {
        border-color: black;
      }
    }
  }

  .button {
    background-color: white;
    color: black;
    width: 100vw;
    margin: 0 0 0 -16px;
    border: none;
    border-top: 1px solid gallery;
    padding: md;
    font-size: 0.875rem;

    span {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &:hover {
        background-color: white;
        border: none;
        border-top: 1px solid gallery;
    }
  }

  .confirmButton {
    margin-top: auto;
    margin-bottom: md;
  }

  .shippingStatusContainer {
    display: none;
    background-color: white;
    width: 100%;
    margin-top: md;
    padding: 0 lg;

    @media breakpointSmUp {
        display: inherit;
      }
  }

  .loaderWrapper {
    padding-top: xl;
    padding-bottom: xl;
  }
}
