@value xs, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdDown, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value mobileProductActions, above from "@/modules/styling/zIndex.module.css";

@layer component {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
  }

  .container.signup {
    @media breakpointMdUp {
      display: none;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: md;
    right: md;
    bottom: md;
    background: white;
    z-index: mobileProductActions;
    border-radius: 12px;
    padding: xl lg;

    @media breakpointMdUp {
      width: 400px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .depopLogo {
    position: fixed;
    left: 50%;
    top: 200px;
    transform: translate(-50%, 0);
    z-index: mobileProductActions;
    width: 154px;
    height: 40px;
    display: block;
    margin-bottom: xl;

    path {
      fill: white;
    }
  }

  .title {
    margin-bottom: xl;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }

  .buttonContainer {
    display: grid;
    grid-template: 1fr minmax(lg, auto) / 1fr;
    width: 100%;
    height: 60px;

    p {
      text-align: center;
      margin: xs 0 md 0;
      line-height: 1;
    }
  }

  .appButton {
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: black;
    padding: 14px 0;
    font-size: 14px;
  }

  .background {
    position: fixed;
    z-index: above;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: contain;
  }

  .linkButton {
    border: none;
  }

  .link {
    text-align: center;
    font-size: 16px;
    line-height: 1;
    color: #3d37bd;
    font-weight: bold;
  }
}
