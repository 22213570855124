@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value HEADER_HEIGHT_PX, HEADER_HEIGHT_MOBILE_PX  from "@/modules/styling/global.module.css";

.appBadgeWrapper {
  margin-top: md;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - HEADER_HEIGHT_PX);

  @media (max-width: 768px) {
    min-height: calc(100vh - HEADER_HEIGHT_MOBILE_PX);
  }
}
