@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    margin-bottom: lg;

    @media breakpointMdDown {
      margin-bottom: md;
    }
  }

  .freeShipping {
    margin-top: sm;
  }

  .desktopOnly {
    @media breakpointMdDown {
      display: none;
    }
  }

  .addToBagButton, .buyNowButton {
    margin: sm 0;
  }
}
