@value white, lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .feedHeader {
    text-align: center;
    margin-bottom: md;
    padding-top: lg;
  }

  .footer {
    background-color: white;
    position: sticky;
    bottom: 0;
    margin-left: calc(-1 * md);
    margin-right: calc(-1 * md);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: md;
    border-top: 1px solid lightGrey1;
    transition: bottom 0.2s ease-out;

    a {
      width: 100%;
    }

    &.footerHidden {
      bottom: -80px;
      transition: bottom 0.2s ease-out;
    }
  }
}
