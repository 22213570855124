@value breakpointSmUp, breakpointMdUp, breakpointMdDown, breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .navigationListItem {
    list-style: none;
    cursor: pointer;
  }

  .navigationListItem--smUp {
    display: none;

    @media breakpointSmUp {
      display: flex;
    }
  }

  .navigationListItem--mdUp {
    display: none;

    @media breakpointMdUp {
      display: flex;
    }
  }

  .navigationListItem--mdDown {
    display: none;

    @media breakpointMdDown {
      display: flex;
    }
  }

  .navigationListItem--lgUp {
    display: none;

    @media breakpointLgUp {
      display: flex;
    }
  }
}
