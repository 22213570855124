@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xs, sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white, lightGrey1 from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sellerHubBulkListingTableHeader from "@/modules/styling/zIndex.module.css";
@value PLACEHOLDER_ROW_HEIGHT from "@/modules/styling/global.module.css";

@layer components {
  .container {
    max-width: 100%;
    flex: 1;
    overflow-y: auto;
    z-index: 10;
  }

  .table {
    width: 100%;
    border-spacing: 0;
    overflow-x: scroll;
    overflow-y: auto;
    border-collapse: collapse;
  }

  .tableHead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: sellerHubBulkListingTableHeader;
    box-shadow: 1px 1px 0 lightGrey1;
  }

  .tableHeadRow {
    background-color: white;
  }

  .tableHeading {
    min-width: 104px;
    padding: sm md;
    text-align: left;

    svg {
      margin-left: xs;
    }
  }

  .editLinkContainer,
  .checkboxContainer {
    padding: sm;
  }

  .photo {
    min-width: 104px;
  }

  .headingText {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
  }

  .toolTip {
    display: inline-flex;
    align-items: center;
  }

  .footerButtonContainer {
    min-height: PLACEHOLDER_ROW_HEIGHT;
    display: flex;
    justify-content: center;
    align-items: start;
    position: relative;
    width: 100%;
    padding: sm 0;
  }
}
