@layer components {
  .messageIconWrapper {
    position: relative;
  }
}

@layer overrides {
  .messageIcon {
    vertical-align: middle;
  }
}
