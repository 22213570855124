@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value checkoutItemImageSize, checkoutItemImageBackground from "@/modules/styling/zIndex.module.css";

@layer components {
  .backdrop {
    &::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      z-index: checkoutItemImageBackground;
      background: linear-gradient(to bottom, rgba(black, 0), rgba(black, 0.3));
    }
  }

  .imageContainer {
    border-radius: sm;
    line-height: 0;
    overflow: hidden;
    position: relative;
    composes: backdrop;
  }

  .sizeText {
    color: white;
    position: absolute;
    width: 100%;
    bottom: sm;
    left: sm;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: checkoutItemImageSize;
  }
}
