@value white, lightGrey2, red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value checkoutPurchaseButtonWrapper from "@/modules/styling/zIndex.module.css";

@layer components {
  .wrapper {
    text-align: center;
    z-index: checkoutPurchaseButtonWrapper;
    background-color: white;
    padding: md;

    @media breakpointSmUp {
      background-color: initial;
      padding: initial;
      z-index: initial;
    }
  }

  .fixedMobileWrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    border-top: 1px solid lightGrey2;

    @media breakpointSmUp {
      position: relative;
      background-color: initial;
      border-top: none;
    }
  }

  .buttonWrapper {
    margin-bottom: md;
    text-align: left;
    min-height: var(--minHeight);
    display: flex;
    flex-direction: column;
    gap: md;
  }

  .errorMessage {
    width: 100%;
    text-align: center;
    color: red;
  }
}
