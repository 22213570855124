@value white, black, lightBlue from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, xs, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value outfitFeedItemReactionFlame from "@/modules/styling/zIndex.module.css";

@layer components {
  .mainImageWrapper {
    position: relative;
    margin-bottom: xl;
    margin-right: calc(-1 * md);
    margin-left: calc(-1 * md);

    > img {
      aspect-ratio: 3 / 4;
      width: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(38, 38, 38, 0) 7.61%,
        rgba(38, 38, 38, 0.4) 65.62%
      );
      bottom: 0;
      left: 0;
      width: 100%;
      height: 92px;
    }
  }

  .moderateButtonWrapper {
    position: absolute;
    top: md;
    right: md;
    z-index: 1;
  }

  .userDetailsWrapper {
    position: absolute;
    bottom: md;
    left: md;
    width: calc(100% - 2 * md);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: md;

    p {
      color: white;
    }
  }

  .module {
    margin-bottom: xl;

    .sectionHeader {
      margin-bottom: md;
    }

    .titleWithTag {
      display: flex;
      align-items: center;
    }
  }

  .productsModuleScroll {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow-x: auto;
    display: flex;

    li {
      width: 150px;
      max-width: 150px;
      flex-grow: 1;
      flex-shrink: 0;
      margin-right: sm;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .betaTag {
    color: black;
    display: inline-flex;
    padding: 0 sm;
    border-radius: 12px;
    background-color: lightblue;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin-left: sm;
  }

  .outfitImage {
    display: block;
  }
}

@layer overrides {
  .userDetailsLink {
    min-width: 0;
  }

  .userDetails {
    p {
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
