@value xs, sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .title {
    margin-bottom: sm;
  }

  .transactionHistory {
    display: flex;
    flex-wrap: wrap;
    column-gap: xl;
    row-gap: xs;

    div {
      display: flex;
      gap: calc(xs + xs / 2);
      align-items: center;
    }
  }
}
