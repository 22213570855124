@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .tabPanel {
    padding: md 0;
  }

  .noVerticalPadding {
    padding: md;
  }
}
