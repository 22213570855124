@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .attributesContainer {
    margin: sm 0 0 xs;

    @media breakpointMdDown {
      display: none;
    }
  }
}
