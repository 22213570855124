@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .pageWrap {
    margin: 0 auto;
    width: 100%;
    max-width: 680px;
    padding-left: md;
    padding-right: md;
    background-color: white;
  }
}
