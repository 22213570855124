@value gallery, black, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .spinner {
    margin-right: sm;
  }

  .listenerText {
    display: flex;
    align-items: center;
    margin-bottom: lg;
  }

  .eventRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: sm;
  }

  .eventRowButton {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin-bottom: sm;

    &:hover {
      text-decoration: underline;
    }
  }

  .grid {
    display: flex;
    gap: lg;
  }

  .eventInfo {
    width: 600px;
    border-left: 1px solid gallery;
    padding-left: lg;
  }

  .eventInfoContent {
    margin-top: md;
  }

  .eventInfoRaw {
    padding: sm;
    background: black;
    color: white;
    line-height: 1.5;
    overflow: auto;
  }
}
