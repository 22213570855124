@value breakpointLgDown, breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value black, white, gallery, galleryDark, lightGrey1, lightGrey2, midGrey, doveGray, lightGrey1_r, lightGrey1_g, lightGrey1_b, midGrey_r, midGrey_g, midGrey_b from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value popoverLayer, searchFilterDropdownLayer from "@/modules/styling/zIndex.module.css";
@value HEADER_AND_MEGA_NAV_HEIGHT_DESKTOP_PX, HEADER_HEIGHT_MOBILE_PX  from "@/modules/styling/global.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .filterAndSortContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: md;

    @media breakpointLgUp {
      align-items: flex-start;
    }
  }

  .filtersContainer {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: popoverLayer;
    background: white;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: md;

    @media breakpointLgUp {
      position: relative;
      z-index: unset;
      width: unset;
      height: unset;
      overflow-y: unset;
      flex-flow: row wrap;
    }
  }

  .dropdownsContainer {
    margin: 0 lg;

    @media breakpointLgUp {
      display: flex;
      gap: md;
      margin: 0;
    }
  }

  .mobileFilterButton {
    font-size: md;
    font-family: fontFamilyBody;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: xs;
    flex-grow: 1;
    flex-basis: 0;
    font-weight: bold;
    background-color: transparent;
    border: none;

    /* styles the applied filter count circle */

    span {
      margin-left: xs;
      border: 1px solid;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      font-size: 12px;
      font-weight: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      color: midGrey;
      text-decoration: none;
    }

    @media breakpointLgUp {
      display: none;
      border: none;
    }
  }

  .divider {
    height: xl;
    background: lightGrey2;
    flex-basis: 1px;

    @media breakpointLgUp {
      display: none;
    }
  }

  .flexGrow {
    flex-grow: 1;

    @media breakpointLgUp {
      display: none;
    }
  }

  .searchFilterDropdownContents {
    z-index: searchFilterDropdownLayer;
    background-color: white;

    @media breakpointLgDown {
      position: fixed;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    @media breakpointLgUp {
      width: 300px;
      border: 1px solid rgb(215, 215, 215);
      padding: 0;
    }
  }

  .searchFilterTriggerText {
    line-height: 1;
  }

  .searchFilterTrigger {
    display: flex;
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    align-items: center;
    width: 100%;
    height: 48px;
    justify-content: space-between;
    border-bottom: 1px solid lightGrey1;

    @media breakpointLgUp {
      height: unset;
      width: unset;
      border: 1px solid midGrey;
      border-radius: borderRadiusMedium;
      padding: 7px 10px;

      &:hover {
        border-color: rgba(midGrey_r, midGrey_g, midGrey_b, 0.375);
        background-color: rgba(lightGrey1_r, lightGrey1_g, lightGrey1_b, 0.375);
      }
    }

    p {
      line-height: 1;
    }
  }

  .searchFilterTriggerActive {
    p,
    span {
      font-weight: bold;
    }

    @media breakpointLgUp {
      border-color: midGrey;
    }
  }

  .searchFilterTriggerDisabled {
    cursor: not-allowed;

    p,
    span,
    svg {
      color: midGrey;
    }

    @media breakpointLgUp {
      border-color: midGrey;

      &:hover {
        background-color: unset;
        border-color: midGrey;
      }
    }
  }

  .searchFilterTriggerChevron {
    display: none;
    margin-left: 5px;

    @media breakpointLgUp {
      display: block;
    }
  }

  .searchFilterTriggerForward {
    display: block;

    @media breakpointLgUp {
      display: none;
    }
  }

  .searchFilterTriggerOpen {
    .searchFilterTriggerChevron {
      transform: rotate(180deg);
    }
  }

  .checkboxFilterDropdownOption {
    justify-content: space-between;
    font-size: 14px;
    font-family: var(--font-GtAmericaExtended);
    flex: 1;
    padding: sm md;
    border-top: 1px solid lightgray;
    min-height: 42px;
    cursor: pointer;

    @media breakpointLgUp {
      &:first-of-type {
        border-top: none;
      }
    }

    &:hover {
      background-color: galleryDark;
    }

    span {
      font-weight: var(--font-weight);
    }
  }

  .searchFilterCheckbox {
    justify-content: space-between;
    min-height: 42px;

    &:hover {
      background-color: gallery;
    }
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: lg;

    @media breakpointLgUp {
      display: none;
    }
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;

    @media breakpointLgUp {
      margin: 0;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: lg;
    background: white;
    gap: lg;
    font-family: var(--font-GtAmericaExtended);
    color: doveGray;
    font-size: 12px;

    @media breakpointLgUp {
      display: none;
    }
  }

  .footerButtonsContainer {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: md;
    padding-bottom: 100px;
  }

  .footerButton {
    flex-grow: 1;
  }

  /* This class must be after .filtersContainer to take precedence */
  .hidden {
    display: none;

    @media breakpointLgUp {
      display: inherit;
    }
  }

  .filterWrapper {
    padding-bottom: md;
    background-color: white;

    @media breakpointLgUp {
      padding: md 0;
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .sticky {
    position: sticky;
    top: HEADER_HEIGHT_MOBILE_PX;
    z-index: 2;
    border-bottom: 1px solid lightGrey2;
    animation: fadein 250ms ease-in-out;
    padding-top: md;

    @media breakpointLgUp {
      top: HEADER_AND_MEGA_NAV_HEIGHT_DESKTOP_PX;
    }
  }

  .filtersOpen {
    z-index: popoverLayer;
  }

  .filterOptionDisabled {
    cursor: not-allowed;
    color: midGrey;

    &:hover {
      background: inherit;
    }
  }
}
