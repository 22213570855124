@value xl, md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .wrapper {
    display: flex;
    align-items: center;
  }

.icon {
    display: inline-block;
    width: xl;
    margin-right: md;
  }

.labelWrapper {
    display: flex;
    flex-direction: column;
  }
}
