@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white, lightGrey2 from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 10fr 1fr;
    padding: md;
    background: white;
    border-bottom: solid 1px lightGrey2;
    min-height: 75px;

    @media breakpointLgUp {
      display: none;
    }
  }

  .title {
    text-align: center;
    flex-grow: 1;
  }

  .iconButton {
    padding: sm;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
