/* stylelint-disable-next-line depop/validate-css-imports */
@value container as searchContainer from "../search/styles.module.css";
@value sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    composes: searchContainer;
    padding-top: sm !important;
  }

  .popularContainer {
    display: flex;
    flex-direction: column;
    gap: sm;
    width: 100%;

    @media breakpointMdUp {
      gap: md;
      flex-direction: row;
      align-items: center;
    }
  }

  .popularTitle {
    flex-shrink: 0;
  }

  .popularPillsContainer {
    display: flex;
    overflow-x: auto;
    margin-left: calc(-1 * md);
    padding-right: md;
    width: calc(100% + xl);
    gap: md;

    @media breakpointMdUp {
      width: unset;
      margin-left: 0;
      padding-right: 0;
    }
  }
}
