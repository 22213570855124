@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer base {
  .dropdownContainer {
    position: relative;
  }

  .contentContainer {
    animation: fadeIn 0.2s ease;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: md;

    @media breakpointLgUp {
      position: absolute;
      top: calc(100% + 12px);
      height: unset;
      width: unset;
      gap: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}
