@value lightGrey2, black, white, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value lg, md, sm, xl, xs from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value sellerHubMenuMobile from "@/modules/styling/zIndex.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value HEADER_HEIGHT_MOBILE_PX from "@/modules/styling/global.module.css";
@value SELLER_HUB_HEADER_HEIGHT: 128px;
@value NAVIGATION_PANEL_TOP_OFFSET_ADDITIONAL_SPACING: 100px;
@value MOBILE_DROPDOWN_HEIGHT: 56px;
@value HEADER_HEIGHT_PX from "@/modules/styling/global.module.css";
@value NAVIGATION_PLACEHOLDER_WIDTH_PX: 240px;
@value NAVIGATION_BORDER_WIDTH_PX: 1px;
@value PAGE_HEADER_HEIGHT: (SELLER_HUB_HEADER_HEIGHT + HEADER_HEIGHT_PX);

@layer components {
  .menu--desktop {
    display: block;

    @media breakpointMdDown {
      display: none;
    }
  }

  .menu--mobile {
    height: calc(
            100dvh -
            (HEADER_HEIGHT_MOBILE_PX + SELLER_HUB_HEADER_HEIGHT +
            NAVIGATION_PANEL_TOP_OFFSET_ADDITIONAL_SPACING +
            MOBILE_DROPDOWN_HEIGHT)
    );
  }

  .listButton {
    list-style: none;
    margin: sm lg md;

    @media breakpointMdDown {
      display: none;
    }
  }

  .mobileContainer {
    position: relative;
    display: none;
    flex-direction: column;
    z-index: sellerHubMenuMobile;

    @media breakpointMdDown {
      display: flex;
    }
  }

  .mobileCTAButtonContainer {
    width: calc(100% - lg);
    padding: md 0;
    margin: auto;
  }

  .bulkListingCta {
    margin-top: sm;
  }

  /* matches the height and width of the seller hub nav */
  .navigationPlaceholder {
    padding: xl 0 80px;
    border-right: NAVIGATION_BORDER_WIDTH_PX solid lightGrey2;
    min-height: calc(100vh - PAGE_HEADER_HEIGHT);
    width: calc(NAVIGATION_PLACEHOLDER_WIDTH_PX + NAVIGATION_BORDER_WIDTH_PX);
    position: relative;

    @media breakpointMdDown {
      min-height: unset;
      padding: 0;
      border-right: none;
      width: 100%;
    }

    &.fixed {
      overflow-y: auto;
      max-height: calc(100vh - PAGE_HEADER_HEIGHT);
    }

    &.collapsed {
      width: 50px
    }
  }

  .expandBtn {
    position: absolute;
    left: 225px;
    top: 20px;
    background-color: white;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border: 1px solid #ACACAC;
    color: #ACACAC;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &:hover {
      border-color: midGrey;
      color: midGrey;
    }

    &.collapsed {
      left: 34px;
    }

    @media breakpointMdDown {
      display: none;
    }

    & svg {
      position: absolute;
    }

    & path {
      stroke-width: 2px;
    }
  }
}

  @layer overrides {
    .pill {
      margin-left: sm;
      padding: xs sm;
      background-color: #ecebf8;
    }

    .pill > p {
      text-transform: uppercase;
      font-weight: 400 !important;
      margin: 0;
      font-size: 9px;
      line-height: 14px;
      color: black;
    }
  }
