@value black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value HEADER_HEIGHT_PX from "@/modules/styling/global.module.css";

.errorContent {
  display: flex;
  align-items: center;

  /* Height of the Navbar */
  height: calc(100vh - HEADER_HEIGHT_PX);
}

.errorMessage {
  font-size: 24px;
  margin-bottom: 16px;

  & a {
    color: black;
  }
}

.depopLogo {
  margin-top: md;
}
