@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .likesWrapper {
    display: flex;
    align-items: flex-end;
  }

  .likesCount {
    color: white;
    font-size: 12px;
    line-height: 1.35;
    letter-spacing: 0.014px;
  }

  .reaction {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@layer overrides {
  .outfitTileIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: sm;
    transition: transform 0.2s ease-in-out;

    &:hover {
      text-decoration: none;
    }
  }

  .flameActiveIcon {
    /* for Like Icon we need plain color instead of gradient */
    path:nth-of-type(1) {
      fill: #ea4221;
      stroke: #ea4221;
    }
  }
}
