@value breakpointMdDown, breakpointSmDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value white, lightGrey2, black, gorse from "@depop/web-ui-kit/theme/default/colors.module.css";
@value HEADER_HEIGHT_PX from "@/modules/styling/global.module.css";
@value header, skipLink from "@/modules/styling/zIndex.module.css";
@value md from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .headerWrapper {
    position: sticky;
    top: 0;
    background: white;
    width: 100%;
    z-index: header;

    @media breakpointMdDown {
      height: auto;
    }
  }

  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 12px 32px;
    min-height: HEADER_HEIGHT_PX;
    border-bottom: 1px solid lightGrey2;

    @media breakpointMdDown {
      padding: 12px 16px;
      min-height: unset;
    }
  }

  .headerContainer--noBorderBottom {
    @media breakpointMdDown {
      border-bottom: none;
    }
  }

  .logo {
    width: 90px;
    height: 23px;
    display: block;

    @media breakpointMdDown {
      margin: 0 auto;
    }
  }

  .skipLinkContainer {
    position: absolute;
    height: 0;
  }

  .skipToLink {
    color: black;
    clip: rect(0, 0, 0, 0);
    text-decoration: none;
    font-family: var(--font-GtAmericaExtended);
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    width: auto;
    height: auto;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;

    &:focus {
      background-color: gorse;
      clip: unset;
      z-index: skipLink;
      border: black 1px;
      padding: md
    }
  }

  .hideSkipLink {
    @media breakpointMdDown {
      &:focus {
        clip: rect(0, 0, 0, 0);
      }
    }
  }
}
