@value black, white, midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value HEADER_HEIGHT_PX from "@/modules/styling/global.module.css";

@layer components {
  .outerContainer {
    overflow: hidden;
    position: relative;
  }

  .imageContainer {
    transform: var(--transform);
    transition: 0.7s transform;
    display: flex;
    background-image: var(--url);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    min-width: 50vw;
    width: var(--totalSteps);

    @media breakpointMdDown, (orientation: portrait) {
      display: none;
    }
  }

  .contentContainer {
    min-width: 50vw;
    background-color: white;
    height: calc(100vh - HEADER_HEIGHT_PX);
    overflow-y: auto;
    top: 0;
    right: 0;
    position: absolute;

    @media breakpointMdDown, (orientation: portrait) {
      position: relative;
      min-width: 100vw;
    }

    > * {
      opacity: 0;
      visibility: hidden;
      transition: all 0.7s;
      position: absolute;
      top: 0;
      right: 0;
      width: 50vw;
  
      input {
        display: none;
        transition: all 0s;
      }

      @media breakpointMdDown, (orientation: portrait) {
        width: 100vw;
      }
    }
  }

  .activeSlide {
    opacity: 1;
    visibility: visible;

    input {
      display: initial;
      transition: all 0s;
    }
  }
}
