@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .container {
    display: grid;
    margin-top: md;
    grid-template-columns: 1fr 1fr;
    gap: sm;
  }

  .button {
    border: 1px solid midGrey;
    border-radius: 8px;
    padding: sm;

    span {
      font-size: 0.75rem;
      font-family: fontFamilyBody;
    }
  }
}