@value black, lightGrey2, red, white from "@depop/web-ui-kit/theme/default/colors.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointLgUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .messageListHeaderContainer {
    min-height: 75px;
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;
    align-items: center;
    background-color: white;
    padding: md;
    border-bottom: 1px solid lightGrey2;

    @media breakpointLgUp {
      display: none;
    }
  }

  .messageListHeaderNameContainer {
    overflow: hidden;
    text-align: center;
    white-space: nowrap;

    p,
    h3 {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .messageListHeaderBackArrow {
    color: black;
  }

  .depopSupportHeaderContainer {
    background-color: red;
  }

  .depopSupportHeaderBackArrow {
    color: white;
  }

  .iconContainer {
    padding: sm;
    display: flex;
    justify-content: center;
  }

  .depopSupportHeaderText {
    color: white;
  }

  .depopSupportHeaderLogo {
    width: 88px;

    path {
      fill: white;
    }
  }
}
