@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .backgroundsList {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    gap: sm;
    overflow-y: auto;
  }

  .backgroundsListItem {
    position: relative;
    display: block;
    flex-basis: calc(33.333% - 2 * sm / 3);
    aspect-ratio: 1 / 1;
    border: 1px solid #acacac;
    border-radius: 8px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .background {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: var(--background);
    border: none;
    padding: 0;

    &:hover {
      background: var(--background);
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .backgroundItemSelected {
    border: 2px solid #3d37bd;
  }
}
