@value xs, sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .tagsList {
    display: flex;
    gap: sm;
    overflow-x: auto;
    box-sizing: content-box;
    padding-bottom: xs;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .singleList {
    flex-wrap: nowrap;
    max-height: 45px;
  }

  .doubleList {
    flex-flow: column wrap;
    max-height: 100px;
  }

  .tagLink {
    text-decoration: none;
  }

  .tag {
    span {
      font-size: 12px;
      font-weight: normal;
    }
  }
}
