@value xl, md, sm, xs, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value white, black from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .collageCreator {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: md;
    padding: md 0;
    position: relative;
    padding-bottom: lg;
    justify-content: space-between;
  }

  .collageCreatorHeader {
    height: 45px;
    display: flex;
    align-items: center;
  }

  .canvasWrapper {
    background-color: #f7f7f7;
    border-radius: 8px;
    border: 1px solid #acacac;
    overflow: hidden;
    aspect-ratio: 9 / 16;
  }

  .canvas {
    height: 100%;
    width: 100%;
  }

  .canvasControls {
    display: flex;
    flex-basis: 50px;
    flex-shrink: 0;
    justify-content: space-around;
    align-items: center;
    padding: 0 lg;
  }

  .canvasControlIcon {
    color: black;
  }

  .canvasControl {
    padding: 0;
    background-color: white;
    border: none;

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      background-color: white;
    }
  }

  .canvasControlContent {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    gap: xs;
  }

  .canvasControlTitle {
    font-size: 0.75rem;
    color: black;
  }

  .submitButton {
    border-radius: 22px;
    padding: 0 12px;
    height: 44px;
    position: absolute;
    top: 84px;
    right: 40px;
  }

  .iconRedo {
    transform: rotateY(180deg);
  }

  .loadingSpinner {
    text-align: center;
    padding: xl 0;
  }

  .canvasLoadingSpinner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalContent {
    margin: 0 sm;
    padding: md 0;
    border-top: 1px solid #e3e3e3;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .modalWrapper {
    display: flex;
    flex-direction: column;
  }

  .modalHeader {
    width: 100%;
    align-self: flex-end;
    text-align: center;
    font-size: 1rem;
    font-weight: 700;
  }
}
