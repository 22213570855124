@value breakpointMdDown from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value lightGrey1, lightGrey1_r, lightGrey1_g, lightGrey1_b, lightGrey2, a11yYellow from "@depop/web-ui-kit/theme/default/colors.module.css";
@value xs, md, lg from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
    .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: transparent;
        padding: md md md lg;
        cursor: pointer;
        border: solid lightGrey2;
        border-width: 0 0 1px 0;
        text-align: left;

        &:hover {
        background-color: rgba(lightGrey1_r, lightGrey1_g, lightGrey1_b, 0.4)
        }

        &:focus {
            box-shadow: 0 0 0 xs a11yYellow;

            /* Visible in Windows high-contrast themes: https://stackoverflow.com/a/52616313 */
            outline: xs dotted transparent;
        }

        @media breakpointMdDown {
            padding: md 0;
            justify-content: flex-start;
        }
    }

    .row--is-active {
        background-color: lightGrey1;

        &:hover {
           background-color: lightGrey1;
        }
    }
}